var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand-lg navbar-light bg-white" },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: { path: "/" } } },
              [_vm._v(_vm._s(_vm.$root.siteName))]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "navbarCollapse" },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("ul", { staticClass: "navbar-nav ms-auto" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/dashboard" } },
                        },
                        [_vm._v("Dashboard")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/billing" } },
                        },
                        [_vm._v("Billing")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/settings" } },
                        },
                        [_vm._v("Settings")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.$root.context.id
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "navbar-btn btn btn-sm btn-primary lift ms-auto",
                        attrs: { to: { path: "/logout" } },
                      },
                      [_vm._v("\n                    Logout\n                ")]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass:
                          "navbar-btn btn btn-sm btn-primary lift ms-auto",
                        attrs: { to: { path: "/login" } },
                      },
                      [_vm._v("\n                    Login\n                ")]
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("main", [_c("router-view")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "position-relative" }, [
      _c(
        "div",
        {
          staticClass:
            "shape shape-bottom shape-fluid-x svg-shim text-gray-200",
        },
        [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 2880 48",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "py-8 py-md-11 bg-gray-200" },
      [_c("Footer")],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Menu",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Menu",
        },
      },
      [_c("i", { staticClass: "fe fe-x" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }