var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "py-8" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-4 col-lg-3" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: { path: "/" } } },
              [_vm._v(_vm._s(_vm.$root.siteName))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-gray-700 mb-2" }, [
              _vm._v("A better way to build."),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-md-3 col-lg-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-md-3 col-lg-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 col-md-3 col-lg-3" }, [
          _c("ul", { staticClass: "list-unstyled text-muted mb-0" }, [
            _c("li", { staticClass: "mb-3" }, [
              _c(
                "a",
                {
                  staticClass: "text-reset text-nowrap",
                  attrs: { href: `mailto:${_vm.$root.siteEmail}` },
                },
                [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$root.siteEmail) +
                      "\n                        "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "list-unstyled list-inline list-social mb-6 mb-md-0" },
      [
        _c("li", { staticClass: "list-inline-item list-social-item me-3" }, [
          _c(
            "a",
            { staticClass: "text-decoration-none", attrs: { href: "#!" } },
            [
              _c("img", {
                staticClass: "list-social-icon",
                attrs: {
                  src: "assets/img/icons/social/instagram.svg",
                  alt: "...",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "list-inline-item list-social-item me-3" }, [
          _c(
            "a",
            { staticClass: "text-decoration-none", attrs: { href: "#!" } },
            [
              _c("img", {
                staticClass: "list-social-icon",
                attrs: {
                  src: "assets/img/icons/social/facebook.svg",
                  alt: "...",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "list-inline-item list-social-item me-3" }, [
          _c(
            "a",
            { staticClass: "text-decoration-none", attrs: { href: "#!" } },
            [
              _c("img", {
                staticClass: "list-social-icon",
                attrs: {
                  src: "assets/img/icons/social/twitter.svg",
                  alt: "...",
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "list-inline-item list-social-item" }, [
          _c(
            "a",
            { staticClass: "text-decoration-none", attrs: { href: "#!" } },
            [
              _c("img", {
                staticClass: "list-social-icon",
                attrs: {
                  src: "assets/img/icons/social/pinterest.svg",
                  alt: "...",
                },
              }),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }