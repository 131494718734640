var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "row gx-4" },
      _vm._l(_vm.plans, function (plan) {
        return _c(
          "div",
          { class: `col-12 col-md-${parseInt(12 / _vm.plans.length)}` },
          [
            _c("div", { staticClass: "card shadow-lg mb-6 mb-md-0 h-100" }, [
              _c(
                "div",
                { staticClass: "card-body px-4" },
                [
                  _c("div", { staticClass: "text-center mb-3" }, [
                    _c("span", { staticClass: "badge rounded-pill" }, [
                      _c(
                        "span",
                        { staticClass: "h6 text-uppercase text-black" },
                        [_vm._v(_vm._s(plan.name))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c("span", { staticClass: "h4 mb-0 mt-2" }, [
                      _vm._v(_vm._s(plan.currencySymbol)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "price display-4 mb-0",
                        attrs: { "data-monthly": "plan.price" },
                      },
                      [_vm._v(_vm._s(plan.price))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-center text-muted mb-5 text-wrap" },
                    [
                      _vm._v(
                        "\n                        per month\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted mb-5 text-wrap" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(plan.description) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(plan.specifications, function (spec) {
                    return _c("div", { staticClass: "d-flex" }, [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("p", { domProps: { innerHTML: _vm._s(spec) } }),
                    ])
                  }),
                  _vm._v(" "),
                  !_vm.isDisabled(plan.id)
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn w-100 btn-primary",
                          class: {
                            "btn-primary-soft": !plan.is_popular,
                            disabled: _vm.isDisabled(plan.id),
                          },
                          attrs: {
                            to: { path: `/plan/choose/${plan.id}` },
                            role: "button",
                            "aria-disabled": true,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.button) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "badge badge-rounded-circle bg-success-soft mt-1 me-2" },
      [_c("i", { staticClass: "fe fe-check" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }