import Vue from 'vue'

// /**
//  * Vue filter to convert the given value to percent.
//  * http://jsfiddle.net/bryan_k/qauf3qyh/
//  *
//  * @param {String} value    The value string.
//  * @param {Number} decimals The number of decimal places.
//  */
// Vue.filter('percentage', function (value, decimals) {
//     if (!value) {
//         value = 0;
//     }
//     if (!decimals) {
//         decimals = 0;
//     }
//     value = value * 100;
//     value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
//     value = value + '%';
//     return value;
// });

Vue.filter('linebreaks', function (value) {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>')
});
