var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "section-border border-primary" }, [
      _c("div", { staticClass: "container d-flex flex-column" }, [
        _c(
          "div",
          {
            staticClass:
              "row align-items-center justify-content-center gx-0 min-vh-100",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11",
              },
              [
                _c("h1", { staticClass: "mb-0 fw-bold text-center" }, [
                  _vm._v("Registration"),
                ]),
                _vm._v(" "),
                Object.keys(_vm.errors).length < 1
                  ? _c("p", { staticClass: "mb-6 text-center text-muted" }, [
                      _vm._v(
                        "\n                            Register to get access to the API token\n                        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.errors.__all__, function (error) {
                  return _c(
                    "p",
                    { staticClass: "mb-6 text-center text-danger" },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(error) +
                          "\n                        "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "mb-6",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.register.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "email" },
                          },
                          [_vm._v("Email")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.email,
                              expression: "form.email",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.errors.email },
                          attrs: {
                            type: "email",
                            id: "email",
                            placeholder: "Enter your Email",
                          },
                          domProps: { value: _vm.form.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "email", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.email, function (error) {
                          return _c(
                            "small",
                            { staticClass: "form-text text-danger" },
                            [_vm._v(_vm._s(error))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group mb-5" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "password" },
                          },
                          [_vm._v("Password")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.password,
                              expression: "form.password",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.errors.password },
                          attrs: {
                            type: "password",
                            id: "password",
                            placeholder: "Enter your password",
                          },
                          domProps: { value: _vm.form.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.password, function (error) {
                          return _c(
                            "small",
                            { staticClass: "form-text text-danger" },
                            [_vm._v(_vm._s(error))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group mb-5" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "password2" },
                          },
                          [_vm._v("Repeat password")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.password2,
                              expression: "form.password2",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.errors.password2 },
                          attrs: {
                            type: "password",
                            id: "password2",
                            placeholder: "Enter your password again",
                          },
                          domProps: { value: _vm.form.password2 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "password2",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.password2, function (error) {
                          return _c(
                            "small",
                            { staticClass: "form-text text-danger" },
                            [_vm._v(_vm._s(error))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "cf-turnstile",
                      attrs: {
                        "data-sitekey": "0x4AAAAAAAkOYrq8IiRQGPZf",
                        "data-callback": "onTurnstileSuccess",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group mb-5 fs-sm" }, [
                      _c(
                        "label",
                        {
                          class: {
                            "is-invalid": _vm.errors.agree_terms_of_service,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.agree_terms_of_service,
                                expression: "form.agree_terms_of_service",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.form.agree_terms_of_service
                              )
                                ? _vm._i(
                                    _vm.form.agree_terms_of_service,
                                    null
                                  ) > -1
                                : _vm.form.agree_terms_of_service,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.agree_terms_of_service,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "agree_terms_of_service",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "agree_terms_of_service",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.form,
                                    "agree_terms_of_service",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            "\n                                    I accept the\n                                    "
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: { to: { path: "/help/terms-of-service" } },
                            },
                            [
                              _vm._v(
                                "\n                                        Terms of service\n                                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          class: {
                            "is-invalid": _vm.errors.agree_privacy_policy,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.agree_privacy_policy,
                                expression: "form.agree_privacy_policy",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.form.agree_privacy_policy
                              )
                                ? _vm._i(_vm.form.agree_privacy_policy, null) >
                                  -1
                                : _vm.form.agree_privacy_policy,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.agree_privacy_policy,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "agree_privacy_policy",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "agree_privacy_policy",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.form,
                                    "agree_privacy_policy",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            "\n                                    I accept the\n                                    "
                          ),
                          _c(
                            "router-link",
                            { attrs: { to: { path: "/help/privacy-policy" } } },
                            [
                              _vm._v(
                                "\n                                        Privacy Policy\n                                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn w-100 btn-info",
                        attrs: { type: "submit", disabled: _vm.disabled },
                      },
                      [
                        _vm._v(
                          "\n                                Register\n                            "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mb-0 fs-sm text-center text-muted" },
                  [
                    _vm._v(
                      "\n                            Already have an account?\n                            "
                    ),
                    _c("router-link", { attrs: { to: { path: "/login" } } }, [
                      _vm._v("Then login"),
                    ]),
                    _vm._v(".\n                        "),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11",
      },
      [
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            src: "/assets/img/illustrations/illustration-8.png",
            alt: "...",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }