var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "section",
        { staticClass: "position-relative pt-12 pt-md-14 mt-n11 bg-black" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "row align-items-center text-center text-md-start",
              },
              [
                _c("div", { staticClass: "col-12 col-md-6 mx-auto" }, [
                  _c("img", {
                    staticClass: "img-fluid mw-130 float-end mb-6 mb-md-0",
                    attrs: {
                      src: "/assets/img/illustrations/illustration-4.png",
                      alt: "...",
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row align-items-center text-center text-md-start",
              },
              [
                _c(
                  "p",
                  { staticClass: "fs-lg text-primary mb-2 text-uppercase" },
                  [
                    _vm._v(
                      "\n                    Key Features:\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                  _vm._v("\n                    1. "),
                  _c("b", [_vm._v("Competitor Analysis")]),
                  _vm._v(
                    ": Stay ahead of the curve\n                    by monitoring your competitors' social media activities.\n                    Our product provides comprehensive insights into their\n                    content strategies, engagement metrics, and audience\n                    demographics, allowing you to identify opportunities and\n                    refine your own approach.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
                  _vm._v("\n                    2. "),
                  _c("b", [_vm._v("Target Audience Profiling")]),
                  _vm._v(
                    ": Understand your\n                    audience like never before. You can get preferences and\n                    demographics data about audience across various social\n                    media channels. This invaluable data enables you to\n                    tailor your content to resonate with your target\n                    audience effectively.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                  _vm._v("\n                    3. "),
                  _c("b", [_vm._v("Content Planning and Scheduling")]),
                  _vm._v(
                    ": Say goodbye\n                    to last-minute scrambles and hello to strategic content\n                    planning. Our product offers intuitive tools to create,\n                    schedule, and manage your social media content calendar\n                    seamlessly. With customizable scheduling options and\n                    content suggestions based on audience preferences, you\n                    can ensure consistent and engaging posts across all\n                    platforms.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
                  _vm._v("\n                    4. "),
                  _c("b", [_vm._v("Performance Analytics")]),
                  _vm._v(
                    ": Measure the success of\n                    your social media efforts with in-depth performance\n                    analytics. Track key metrics. Our actionable insights\n                    help you identify trends, optimize your strategies, and\n                    drive meaningful results.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                  _vm._v("\n                    5. "),
                  _c("b", [_vm._v("Automation and Integration")]),
                  _vm._v(
                    ": Streamline your\n                    workflow with automation features and seamless\n                    integration with popular social media platforms. From\n                    automated publishing to real-time monitoring of brand\n                    mentions and comments, our product empowers you to\n                    maximize efficiency and stay responsive to your\n                    audience.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
                  _vm._v("\n                    6. "),
                  _c("b", [_vm._v("ROI Tracking")]),
                  _vm._v(
                    ": Gain clarity on the return on\n                    investment (ROI) of your social media campaigns. Our\n                    product provides robust tracking allowing you to measure\n                    the impact of your efforts on business objectives such\n                    as lead generation, brand awareness, and customer\n                    acquisition.\n                "
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }