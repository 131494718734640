<template>
    <div>
        <section class="pt-8 pt-md-11 pb-10 pb-md-15">
            <div class="shape shape-blur-3 svg-shim text-white">
                <svg
                    viewBox="0 0 1738 487"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z"
                        fill="url(#paint0_linear)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="0"
                            y1="0"
                            x2="1049.98"
                            y2="912.68"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop
                                stop-color="currentColor"
                                stop-opacity=".075"
                            />
                            <stop
                                offset="1"
                                stop-color="currentColor"
                                stop-opacity="0"
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 text-center">
                        <h1 class="display-2 text-white">
                            {{ $root.siteName }} Pricing
                        </h1>
                        <p class="lead text-white-80 mb-6 mb-md-8">
                            Choose the plan that's right for you
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="mt-n8 mt-md-n14">
            <Plans />
        </section>
        <section class="py-8 py-md-11">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 text-center">
                        <h2 v-show="!$root.context.id">
                            <router-link
                                class="fw-bold text-primary-desat"
                                :to="{ path: '/registration' }"
                            >
                                Register
                            </router-link>
                            and enjoy!
                        </h2>
                        <p class="fs-lg text-muted mb-7 mb-md-9">
                            Fund up your account in your personal dashboard
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Plans from "./Plans";
export default {
    components: { Plans },
    data() {
        return {};
    },
};
</script>
