var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "section",
        { staticClass: "position-relative pt-12 pt-md-14 mt-n11 pb-md-12" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "row align-items-center text-center text-md-start",
              },
              [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c("img", {
                    staticClass: "img-fluid mw-130 float-end mb-6 mb-md-0",
                    attrs: {
                      src: "/assets/img/illustrations/illustration-4.png",
                      alt: "...",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c("h1", { staticClass: "display-1 fw-bold" }, [
                    _c("div", [_vm._v("About")]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                    _vm._v(
                      "\n                        Introducing our cutting-edge Social Media Management\n                        product, designed to revolutionize your digital\n                        marketing efforts. Our platform combines advanced\n                        automation with insightful analytics to empower\n                        businesses to excel in the competitive landscape of\n                        social media.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                    _vm._v(
                      "\n                        In today's digital age, an effective social media\n                        presence is essential for business success. With our\n                        SMM app, you can unlock the full potential of social\n                        media marketing, drive meaningful engagement, and\n                        achieve your business goals with confidence.\n                        Experience the future of digital marketing – start\n                        optimizing your social media strategy today!\n                    "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }