<template lang="html">
</template>

<script>
import { EventBus } from "@/bus";

export default {
    created() {
        this.$root.Logout.save().then(resp => {
            EventBus.$emit('context:updated', {id: null})
            window.location.href = '/'
        })
    }
}
</script>
