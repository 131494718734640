<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container">
                <router-link class="navbar-brand" :to="{ path: '/' }">{{
                    $root.siteName
                }}</router-link>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <!-- Toggler -->
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i class="fe fe-x"></i>
                    </button>

                    <!-- Navigation -->
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <router-link
                                :to="{ path: '/about' }"
                                class="nav-link"
                                >About</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="{ path: '/features' }"
                                class="nav-link"
                                >Features</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="{ path: '/pricing' }"
                                class="nav-link"
                                >Pricing</router-link
                            >
                        </li>
                    </ul>

                    <!-- Button -->
                    <router-link
                        :to="{ path: '/dashboard' }"
                        class="navbar-btn btn btn-sm btn-info lift ms-auto"
                        v-if="$root.context.id"
                    >
                        Console
                    </router-link>

                    <router-link
                        :to="{ path: '/login' }"
                        class="navbar-btn btn btn-sm btn-info lift ms-auto"
                        v-else
                    >
                        Login
                    </router-link>
                </div>
            </div>
        </nav>
        <main>
            <router-view></router-view>
        </main>
        <Footer />
    </div>
</template>

<script>
import Footer from "./Footer";
export default {
    components: { Footer },
};
</script>
