import Home from './Home'
import Login from './Login'
import Logout from './Logout'
import Registration from './Registration'
import NotFound from './NotFound'
import Features from './Features'
import About from './About'
import Pricing from './Pricing'

export { Home }
export { Login }
export { Logout }
export { Registration }
export { NotFound }
export { About }
export { Features }
export { Pricing }
