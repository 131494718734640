function cached(resource) {
    let get = resource.get;
    resource._cache = null;
    resource.get = function(params) {
        if (!resource._cache)
            resource._cache = get(params);
        return resource._cache;
    }
    return resource;
}

export default {
    Login() {
        return this.$resource(this.apiUrl + 'users/login')
    },
    Register() {
        return this.$resource(this.apiUrl + 'users/register')
    },
    Context() {
        return this.$resource(this.apiUrl + 'users/context')
    },
    Logout() {
        return this.$resource(this.apiUrl + 'users/logout')
    },
}
