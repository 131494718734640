<template>
    <div>
        <section class="section-border border-primary">
            <div class="container d-flex flex-column">
                <div
                    class="row align-items-center justify-content-center gx-0 min-vh-100"
                >
                    <div
                        class="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
                    >
                        <img
                            src="/assets/img/illustrations/illustration-2.png"
                            alt="..."
                            class="img-fluid"
                        />
                    </div>
                    <div
                        class="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
                    >
                        <h1 class="mb-0 fw-bold text-center">Login</h1>
                        <p
                            class="mb-6 text-center text-muted"
                            v-if="Object.keys(errors).length < 1"
                        >
                            You will get access to the dashboard
                        </p>
                        <p
                            class="mb-6 text-center text-danger"
                            v-for="error in errors.__all__"
                        >
                            {{ error }}
                        </p>
                        <form class="mb-6" @submit.prevent="login">
                            <div class="form-group">
                                <label class="form-label" for="email"
                                    >Email</label
                                >
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter your Email"
                                    :class="{ 'is-invalid': errors.email }"
                                    v-model="form.email"
                                />
                                <small
                                    class="form-text text-danger"
                                    v-for="error in errors.email"
                                    >{{ error }}</small
                                >
                            </div>
                            <div class="form-group mb-5">
                                <label class="form-label" for="password"
                                    >Password</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter your password"
                                    :class="{ 'is-invalid': errors.password }"
                                    v-model="form.password"
                                />
                                <small
                                    class="form-text text-danger"
                                    v-for="error in errors.password"
                                    >{{ error }}</small
                                >
                            </div>
                            <div class="form-group mb-5 fs-sm">
                                <label
                                    :class="{
                                        'is-invalid':
                                            errors.agree_terms_of_service,
                                    }"
                                >
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        v-model="form.agree_terms_of_service"
                                    />
                                    I accept the
                                    <router-link
                                        :to="{ path: '/help/terms-of-service' }"
                                    >
                                        Terms of service
                                    </router-link>
                                </label>
                                <label
                                    :class="{
                                        'is-invalid':
                                            errors.agree_privacy_policy,
                                    }"
                                >
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        v-model="form.agree_privacy_policy"
                                    />
                                    I accept the
                                    <router-link
                                        :to="{ path: '/help/privacy-policy' }"
                                    >
                                        Privacy Policy
                                    </router-link>
                                </label>
                            </div>
                            <button
                                class="btn w-100 btn-info"
                                type="submit"
                                :disabled="disabled"
                            >
                                Enter
                            </button>
                        </form>
                        <p class="mb-0 fs-sm text-center text-muted">
                            Don't have an account yet?
                            <router-link :to="{ path: '/registration' }"
                                >Register</router-link
                            >.
                        </p>
                        <p class="mb-0 fs-sm text-center text-muted">
                            Or you can
                            <router-link :to="{ path: '/forgot' }"
                                >reset your password</router-link
                            >
                            if you forgot
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { EventBus } from "@/bus";
export default {
    data() {
        return {
            form: {
                email: "",
                password: "",
                agree_terms_of_service: true,
                agree_privacy_policy: true,
            },
            errors: {},
            disabled: false,
        };
    },
    created() {
        EventBus.$on("context:loaded", (context) => {
            if (!context.id) return;
            this.$router.replace({ path: "/dashboard" });
        });
        EventBus.$emit("context:request");
    },
    methods: {
        login() {
            this.disabled = true;
            this.errors = {};
            this.$root.Login.save(this.form)
                .then((resp) => {
                    this.errors = resp.body.errors || {};
                    if (resp.body.state) {
                        EventBus.$emit("context:changed");
                        setTimeout(() => {
                            this.$router.replace({ path: "/dashboard" });
                        });
                    }
                    this.disabled = false;
                })
                .catch((resp) => {
                    let detail = resp.body.detail;
                    if (typeof detail != "string") {
                        for (let item of detail) {
                            let keys = item["loc"];
                            let key = keys[keys.length - 1];
                            this.$set(this.errors, key, [item["msg"]]);
                        }
                    } else {
                        this.errors = {
                            __all__: [resp.body.detail || resp.statusText],
                        };
                    }
                });
            setTimeout(() => {
                this.disabled = false;
            }, 5000);
        },
    },
};
</script>
<style scoped>
.is-invalid {
    color: red;
}
.is-invalid a {
    color: brown;
}
</style>
