<template>
    <div class="container">
        <div class="row gx-4">
            <div
                :class="`col-12 col-md-${parseInt(12 / plans.length)}`"
                v-for="plan in plans"
            >
                <div class="card shadow-lg mb-6 mb-md-0 h-100">
                    <div class="card-body px-4">
                        <div class="text-center mb-3">
                            <span class="badge rounded-pill">
                                <span class="h6 text-uppercase text-black">{{
                                    plan.name
                                }}</span>
                            </span>
                        </div>
                        <div class="d-flex justify-content-center">
                            <span class="h4 mb-0 mt-2">{{
                                plan.currencySymbol
                            }}</span>
                            <span
                                class="price display-4 mb-0"
                                data-monthly="plan.price"
                                >{{ plan.price }}</span
                            >
                        </div>
                        <p class="text-center text-muted mb-5 text-wrap">
                            per month
                        </p>
                        <p class="text-muted mb-5 text-wrap">
                            {{ plan.description }}
                        </p>
                        <div class="d-flex" v-for="spec in plan.specifications">
                            <div
                                class="badge badge-rounded-circle bg-success-soft mt-1 me-2"
                            >
                                <i class="fe fe-check"></i>
                            </div>
                            <p v-html="spec"></p>
                        </div>
                        <router-link
                            :to="{ path: `/plan/choose/${plan.id}` }"
                            class="btn w-100 btn-primary"
                            role="button"
                            :aria-disabled="true"
                            :class="{
                                'btn-primary-soft': !plan.is_popular,
                                disabled: isDisabled(plan.id),
                            }"
                            v-if="!isDisabled(plan.id)"
                        >
                            {{ button }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        button: {
            type: String,
            default: "Buy",
        },
        disabledId: {
            type: Number,
        },
    },
    data() {
        return {
            plans: [
                [
                    80,
                    "Standart",
                    "Get info and statistic about anithing accounts",
                ],
                [
                    190,
                    "King",
                    "Get statistic about anithing accounts and ability to post content on one account",
                ],
                [
                    350,
                    "Bigbro",
                    "The ability to post content on multiple accounts and a convenient calendar with content planning",
                ],
                [
                    "X",
                    "Custom",
                    "Contact us and oure development team create you're personal opportunities",
                ],
            ].map((item) => {
                return {
                    price: item[0],
                    name: item[1],
                    currencySymbol: "$",
                    description: item[2],
                };
            }),
        };
    },
    created() {
        // this.get()
    },
    methods: {
        get() {
            this.$root.Plan.get().then((resp) => {
                this.plans = resp.body;
            });
        },
        isDisabled(id) {
            return id == this.disabledId;
        },
    },
};
</script>
