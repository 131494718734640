var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("nav", { staticClass: "navbar navbar-expand-lg navbar-dark" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: { path: "/" } } },
              [_vm._v(_vm._s(_vm.$root.siteName))]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "navbarCollapse" },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("ul", { staticClass: "navbar-nav ms-auto" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/about" } },
                        },
                        [_vm._v("About")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/features" } },
                        },
                        [_vm._v("Features")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { path: "/pricing" } },
                        },
                        [_vm._v("Pricing")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.$root.context.id
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "navbar-btn btn btn-sm btn-info lift ms-auto",
                        attrs: { to: { path: "/dashboard" } },
                      },
                      [
                        _vm._v(
                          "\n                    Console\n                "
                        ),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass:
                          "navbar-btn btn btn-sm btn-info lift ms-auto",
                        attrs: { to: { path: "/login" } },
                      },
                      [_vm._v("\n                    Login\n                ")]
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("main", [_c("router-view")], 1),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("i", { staticClass: "fe fe-x" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }